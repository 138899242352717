import { http } from '../../../api/http-common';

const list = async (params) => {
	try {
		const { data } = await http.get('tool', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const updatePassword = async (payload) => {
	try {
		const { data } = await http.post('auth/password-update', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export { list, updatePassword };
