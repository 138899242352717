import * as userService from './service/user.service';

const state = {
	user: null,
};

const mutations = {
	SET_USER(state, payload) {
		state.tool = payload;
	},
};

const actions = {
	async updatePassword(context, payload) {
		try {
			const response = await userService.updatePassword(payload);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response };
		}
	},
};

const getters = {
	getHeaderById: (state) => (id) => {
		return { ...state.user, id };
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
